
import React from "react";


import { Card, Spinner} from 'react-bootstrap';

const cardinfo = "d-flex justify-content-center"; //justify-center

const CardHeaderLoading = (props) => {

  
    return (
      <>
            <Card.Header className={cardinfo} style={{height: 50}}>{props.loading && <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>}&nbsp;&nbsp;&nbsp;{props.title}</Card.Header>
      </>
    )
    
  };

  export {CardHeaderLoading}