
import { TbChevronDown, TbChevronsDown, TbChevronUp, TbChevronsUp, TbCircle, TbHandStop, TbCreativeCommonsOff, TbColumnInsertLeft, TbArrowBigTop, TbArrowBigUpLine, TbArrowBigUpLines, TbArrowBigDown, TbArrowBigDownLine, TbArrowBigDownLines } from 'react-icons/tb';

import React, { useEffect, useState, useRef } from 'react';

import { Modal, Container, Row, Col, Spinner, Button, Table } from 'react-bootstrap'



import Accordion from 'react-bootstrap/Accordion';
import { DataStore } from 'aws-amplify';

import {ApiDelete, ApiPut, ApiGet, ApiGetNoWait, ApiGetArray, ApiCallArray, ApiHeaders} from './Api';
import { ConsoleLogger } from '@aws-amplify/core';

//const admin = require('./data/admin.json');
const dataImport = require('./data/data.json');

const labelWidth = 140;

/*const camelize = (str) => {
    return str.toLowerCase().replace(/\W+(.)/g, function(match, chr) {
        return chr.toUpperCase();
    });
}
*/


// magic numbers                                // automate fetching this value by:

const SELECT_ID =               "dif#title";    // automate by putting this value in a special db lookup :-(
const DEFAULT_PROBABILITY_ID =  "sco#neutral";  // automate by searching scores for 0 adjustmment
const FILTERS_IDS =             "fil#";         // automate by looking up affector checking sub = fil (still a magic number, unless we add a boolean)
// table names ? // not substituded yet
const ADMIN_VIEW_TABLE =        "adminView";
const AFFECTOR_TABLE=           "affector";
const DIFFERENTIAL_TABLE =      "differential";
const SCORE_TABLE_TABLE =       "score";

const SELECT_ALL = "fil#selectAll"


const camelize = (str) => {
    return str.toLowerCase().replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
}
/*
const selector = {
"displayField"                          // option field to display to user
"id": "diff_selector",                  // the id to be used for the row key
"name": "id",                           // the name of the unique id field
"onChange": onChange,                   // called when selection changes
"readOnly": selectorReadOnly,           // mark as readonly if true
 "options": props.data.differential,    // the array to fetch the drop down list from
 "title": title,                        // title line for the drop down e.g. "Please Select"
 "value": select                        // the current selected item
};
*/

function DataSelect(props) {

    console.log('DataSelect', props);

    //const key   = props.data.hasOwnProperty('id')? `select_${props.data.id}` : `select_${generalKey++}`;
    const key   = props.data.hasOwnProperty('id')? props.data.id : generalKey++;

    const displayField = props.data.displayField;
    const displayName  = props.data.name;

    //const key = `select_${generalKey++}`;
    //const inputKey = `select_${key}`;

    console.log('DataSelect key', key);

    //readOnly key={inputKey}
    //if (props.readOnly) { key={`option_${option.id}`} 
    let options = [];
    if (props.data.hasOwnProperty('title')) {   // this is the title line for the drop down e.g. "Please Select"
        options.push(<option disabled key={`option_${key}`} value={""}>{props.data.title}</option>);
    }
    //props.data.options.forEach(option => (options.push(<option disabled={props.data.readOnly} key={`select_${generalKey++}`} value={option.id}>{option.label}</option>)));
    props.data.options.forEach(option => {  
                                            const displayText = option.hasOwnProperty(displayField) && (option[displayField].trim() !== "")
                                                ? option[displayField] : option[displayName];
                                            console.log('Option', displayField, displayName, option[displayName]);
                                            options.push(<option disabled={props.data.readOnly} key={`option_${option.id}`} value={option.id}>{displayText}</option>);
                                         });

    const output = [
        <select key={`select_${key}`} name={props.data.name} required value={props.data.value} onChange={props.data.onChange}>
            {options}
        </select>
    ];


    return output;

}

const TableDataSelect = (props) => {

    console.log('TableDataSelect', props);

    const key   = props.hasOwnProperty('id') ? `td_${props.id}` : `td_${generalKey++}`;
    //const inputKey = `select_${key}`

    //console.log('key', key);
    //console.log('key', inputKey);


//readOnly key={inputKey}
    //if (props.readOnly) {
/*
        let options = [];
        props.options.forEach(option => (options.push(<option key={`select_${option.id}`} value={option.id}>{option.label}</option>)));

        output = [<td className="TableData" key={key}><DataSelect /></td>]
*/
    return [<td className="TableData" key={key}><DataSelect data={props}/></td>];

}

const TableDataRadio = (props) => {

    //console.log('TableDataRadio', props);

    const key   = (props.hasOwnProperty('id') && props.hasOwnProperty('group')) ? `td_${props.group}.${props.id}` : `td_${generalKey++}`;
    const inputKey = `radio_${key}`



    let output = [];

    //if (props.readOnly) {
    //    output = [<td className="TableData" key={key}><input type="radio"  disabled={props.readOnly}  key={inputKey} value={props.id} name={props.group} checked={props.checked}  /></td>]; 
    //} else {
        output = [<td className="TableData" key={key}><input type="radio"  disabled={props.readOnly} key={inputKey} value={props.id} name={props.group} checked={props.checked} onChange={props.onChange}  /></td>]; 
    //}
    //console.log('key', key);
    //console.log('key', inputKey);

    return output;
}

const TableDataTextArea = (props) => {

    const key   = props.hasOwnProperty('id') ? `td_datatextarea_${props.id}` : `td_${generalKey++}`;

    let output = [];

    //output = [<td className="TableData" key={key}><input type="textarea"  name={props.id} value={props.value} onChange={props.onChange}  /></td>]; //value={props.id}
    output = [<td className="TableData" key={key}><textarea name={props.id} value={props.value} onChange={props.onChange} disabled={props.readOnly} rows={props.rows} cols={props.cols} /></td>]; //value={props.id}

   //console.log('key', key);

    return output;
}

const TableDataText = (props) => {

    const key   = props.hasOwnProperty('id') ? `td_datatext_${props.id}` : `td_${generalKey++}`;
    //const ref   = props.hasOwnProperty('ref') ? props.ref : "";

    let output = [];
/*
    if (props.autoFocus) {
        output = [<td className="TableData" key={key}><input type="text"  name={props.id} defaultValue={props.value} onChange={props.onChange}  /></td>]; //value={props.id}
    } else {
       //console.log("AUTOFOCUS------------------------");
        output = [<td className="TableData" key={key}><input type="text" autoFocus name={props.id} value={props.value} onChange={props.onChange}  /></td>]; //value={props.id}
    }
*/

    //if (ref === "") {
    if (!props.hasOwnProperty('ref')) {
        output = [<td className="TableData" key={key}><input type="text" disabled={props.readOnly} name={props.id} value={props.value} onChange={props.onChange}  /></td>]; //value={props.id}
    } else {
       //console.log("REF------------------------");
        output = [<td className="TableData" key={key}><input type="text" disabled={props.readOnly} ref={props.ref} name={props.id} value={props.value} onChange={props.onChange}  /></td>]; //value={props.id}
    }

    console.log('TableDataText', key, props);

    return output;
}

const TableData = (props) => {

   //console.log('TD', props);

    const label = props.hasOwnProperty('label') ? props.label : "";
    const key   = props.hasOwnProperty('id') !== "" ? `td_data${props.id}` : `td_${generalKey++}`;

    

        //output = [<td className="TableHeader" key={key}><input type="radio" value={props.id} name={props.group} checked={props.checked} onChange={props.onChange}  /></td>]; //checked={false} 
    const output = [<td className="TableData" key={key}>{props.payLoad}</td>]; //checked={false} 
    

   //console.log('key', key, output);

    return output;
}

const HeaderContent = (props) => {
    const label = props.hasOwnProperty('label') ? props.label : "";
    const icon = props.hasOwnProperty('icon') ? props.icon : "";


    let output = "";

    switch (icon) {
        case "stop":     output = <><TbHandStop className={"icon"} /><div className="iconHint">{label}</div></>;       break;
        case "rare":     output = <><TbArrowBigDownLines className={"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "vLow":     output = <><TbArrowBigDownLine className={"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "low":      output = <><TbArrowBigDown className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "neutral":  output = <><TbCircle className= {"icon"} /><div className="iconHint">{label}</div></>;        break;
        case "high":     output = <><TbArrowBigTop className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "vHigh":    output = <><TbArrowBigUpLine className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "common":   output = <><TbArrowBigUpLines className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
        default:         output = label;
}

    
    return (output); 
}

/*
        case "stop":     output = <><TbHandStop className={"icon"} /><div className="iconHint">{label}</div></>;       break;
        case "vLow":     output = <><TbChevronsDown className={"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "low":      output = <><TbChevronDown className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "neutral":  output = <><TbCircle className= {"icon"} /><div className="iconHint">{label}</div></>;        break;
        case "high":     output = <><TbChevronUp className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
        case "vHigh":    output = <><TbChevronsUp className= {"icon"} /><div className="iconHint">{label}</div></>;   break;
*/

const TableHeader = (props) => {


    const label = props.hasOwnProperty('label') ? props.label : "";
    const key   = label !== "" ? label : `th_${generalKey++}`;

    const colSpan = props.hasOwnProperty('colSpan') ? props.colSpan : 1;
    const rowSpan = props.hasOwnProperty('rowSpan') ? props.rowSpan : 1;
    const width =  props.hasOwnProperty('width') ? props.width : "auto";
    const className =  props.hasOwnProperty('className') ? props.className : "TableHeader";

    console.log('WIDTH TableHeader', width);

    const output = [<th className={className} key={key} rowSpan={rowSpan} colSpan={colSpan} width={width}>{HeaderContent(props)}</th>];

    //const output = [<th className="TableHeader" key={key} rowSpan={rowSpan} colSpan={colSpan} width={width}>{HeaderContent(props)}</th>];


    //console.log('key', key);

    return output;
    
}

var generalKey = 0;

const TableRow = (props) => {

    
    const key = `tr_${generalKey++}`;

    //console.log('key', key);

    //return `<tr>${props}</tr>`;
    return [<tr className="TableDataRow" key={key}>{props}</tr>];


}

const TableHeaderRow = (props) => {

    
    const key = `tr_${generalKey++}`;

    //console.log('key', key);

    //return `<tr>${props}</tr>`;
    return [<tr className="TableHeaderRow" key={key}>{props}</tr>];

}




const ViewTable = (props) => {

        
    const key = `table_${generalKey++}`;

   //console.log('key', key);

    console.log('TABLE', key, props);

    //xxxconst output= <div key={key}><table><tbody>{props}</tbody></table></div>;
    const output= <div key={key}><table className="Table">{props}</table></div>;
    //const output= <div key={key}><Table striped bordered responsive={false} width={"auto"}>{props}</Table></div>;

    return (output);

}

const ViewTableBody = (content, key) => {

        
    //const key = `table_${generalKey++}`;

    const thisKey = "body"+key;
    console.log('ViewTableBody key', thisKey);


    //xxxconst output= <div key={key}><table><tbody>{props}</tbody></table></div>;
    const output= <tbody key={thisKey} className="TableBodySection">{content}</tbody>

    return (output);

}

const ViewTableHeader = (content, key) => {

        
    //const key = `table_${generalKey++}`;
    const thisKey = "header"+key;
    console.log('ViewTableHeader key', thisKey);

   //console.log('key', key);

    //xxxconst output= <div key={key}><table><tbody>{props}</tbody></table></div>;
    const output= <thead key={thisKey} className="TableHeaderSection">{content}</thead>

    return (output);

}





var generalKey = 0;


const ViewTextArea = (props) => {

    console.log('VIEWTEXTAREA:', props);

    let output = [];
    let line = []; 


    const affectors = props.control.affector.map(item => (props.affector.find(affector => affector.id === item)));


    // data rows (with row headers)
    affectors.forEach(field => {
        const key = field.id;
        const value = props.record.hasOwnProperty(key) ? props.record[key] : "";
        line = [];
        line.push(TableHeader({...field, "width": labelWidth}));
        line.push(TableDataTextArea({...field, "value": value, "onChange": props.mutate, "readOnly": props.readOnly, "rows": 10, "cols": 60}))
        output.push(TableRow(line));
    });

    return ViewTable(ViewTableBody(output, props.control.sub));
}

//const ViewText = (control, record, data, mutate, filter) => {
const ViewText = (props) => {

    console.log('VIEWTEXT props:', props);

    let output = [];
    let line = []; 

    const affectors = props.control.affector.map(item => (props.affector.find(affector => affector.id === item)));

    
     console.log('VIEWTEXT:', affectors);

    // data rows (with row headers)
    affectors.forEach(field => {
        const key = field.id;
        let value = "";
        if (props.record.hasOwnProperty(key)) { 
            value = props.record[key];

        } else if (props.filter.hasOwnProperty(key)) {
            value = props.filter[key];
        }

       //console.log('Viewtext value++++++++++++++++++++', key, props.record.hasOwnProperty(key), props.filter.hasOwnProperty(key), value);

        line = [];
        line.push(TableHeader({...field, "width": labelWidth}));

        line.push(TableDataText({...field, "value": value, "onChange": props.mutate, "readOnly": props.readOnly}))
       
        output.push(TableRow(line));
    });

    return ViewTable(ViewTableBody(output, props.control.sub));
}
/*
const XXXmutate = (event) => {                             // field changes
    const details = event.target;       
    console.log('MUTATE', details);



    let newRecord = {}
    if (details.name.startsWith(FILTERS_IDS)) {              // special case for filters
        newRecord = {...filter};        
        newRecord[details.name] = details.value;
        setFilter(newRecord);
       //console.log('MUTATE filter++++++++++++++++++', filter);
       console.log('FILTER--------------------------', newRecord);
    }
    else {                                              // current differential field change
        newRecord = {...record};
        if (details.value === DEFAULT_PROPABILITY_ID)            // default not stored - so delete key value pair
        {
            delete newRecord[details.name];

        } else {
            newRecord[details.name] = details.value;
        }

        setRecord(newRecord);

        setChanged(true);

        console.log('RECORD--------------------------', newRecord);
    }
}

*/
const ViewTickGrid = (props) => {


    const [modal, setModal] = useState(null);


    var affectorsInScope = []; 
    var affectorsFiltered = [];


    const onSelectAll = (event) => {      
        // dialog
        props.mutate(event);    


        const pairs = {};
        affectorsInScope.forEach(record => {
            pairs[record.id] = event.target.value;
        })
        props.mutate({"bulk": pairs});
        console.log('POST MUTATE', event.target, pairs);

        setModal(null);
    }

    const onSelectAllRequest = (event) => {
        setModal({"header": "Change ALL Breeds",
        "body": "Please Confirm if you wish to proceed, this will change ALL Breeds", 
        "yes": "Yes",
        "no": "No",
        "yesEvent": event.target.value,
        "yesName":  event.target.name });          
    }


    console.log('VIEWTICKGRID:', props);

    const hideCategoryLabel = props.control.hideCategoryLabel;
    const rowHeaderColumns =  2;                            // the no. of columns that are used as a header for each data row
    const columnHeaderRows =  hideCategoryLabel  ? 1 : 2;   // The no. of rows that make up the header  - if hide then dont display the Category label; 
    const columnHeaders = props.score;

    const columnsTotal = columnHeaderRows + columnHeaders.length;
    const colBlank = { "colSpan": columnHeaderRows, "rowSpan": rowHeaderColumns }; // the cell in the top left of the table 
    const colTitle = { "label": props.control.label, "colSpan": columnHeaders.length};

    let header = [];
    let body = [];
    let output = [];
    let line = []; 


    // HEADER ROWS
    // Column Header rows
    line = []; 
    line.push(TableHeader(colBlank));
    line.push(TableHeader(colTitle));
    header.push(TableHeaderRow(line));

    line = []; 
    props.score.forEach(field => line.push(TableHeader(field)));
    header.push(TableHeaderRow(line));


    // FETCH AFFECTORS (EITHER AN ARRAY OR A LIST)
    if (Array.isArray(props.control.affector)) {
            // list of affectors provides
            affectorsInScope = props.control.affector.map(item => (props.affector.find(affector => affector.id === item)));
    }
    else {
            // table and sub type specified for list

            const table = props.control.affector.table;
            affectorsInScope = props[table].map(item => item)

            if        (props.control.affector.hasOwnProperty("sub"))    { 

                const subset = props.control.affector["sub"];    
                affectorsInScope = affectorsInScope.filter(filter => filter.sub === subset);

                console.log('ViewTickGrid SUB...................', subset, affectorsInScope);

            } else if (props.control.affector.hasOwnProperty("aView")) {

                 const subset = props.control.affector["aView"];
                 affectorsInScope = affectorsInScope.filter(filter => filter.aView === subset);


                console.log('ViewTickGrid  aView...................', subset, affectorsInScope);

            }
    }       
    


    // APPLY USER FILTER (IF ANY)
    // use a filter (if present) to filter the list (otherwise display whole list)
    const thisFilter = ((props.control.hasOwnProperty("filter")) && (props.filter.hasOwnProperty(props.control.filter))) ? props.filter[props.control.filter] : "";
    affectorsFiltered = affectorsInScope.filter(filter => {
                                                    const upperCase = filter.label.toUpperCase();
                                                    return  upperCase.startsWith(thisFilter.toUpperCase());
    });


    console.log('AAAAAAAAAA', props, affectorsInScope );


    // SELECT_ALL CONTENT (IF THERE IS ONE)
    if (props.control.hasOwnProperty("selectAll")) {
        const selectAllId = props.control["selectAll"];
        const selectAll = props.affector.find(record => record.id == selectAllId);
        console.log('selectAll', selectAllId, selectAll);
        let line = [];

        // only add category label field if its required
        if (!hideCategoryLabel) {
            const rowTitle = { "label": "test"};
            line.push(TableHeader({...rowTitle, "width": labelWidth, "className": "TableHeaderFixed"}));               
        }
        line.push(TableHeader({...selectAll, "width": labelWidth}));     // lable for the row

        props.score.forEach(field => {
         
            const checked = props.filter.hasOwnProperty(selectAllId) ? (field.id === props.filter[selectAllId]) : false;

            //line.push(TableDataRadio({...field, "group": selectAllId, "onChange": props.mutate, "readOnly": props.readOnly, "checked": checked}))

            line.push(TableDataRadio({...field, "group": selectAllId, "onChange": onSelectAllRequest, "readOnly": props.readOnly, "checked": checked}))
        });

        body.push(TableRow(line));
    }


    // DATA ROWS
    // Row Headers and Data
    for (let index = 0; index < affectorsFiltered.length;) {

        // het start and end indexes for one category
        let start = index;
        let end   = index;
        for (index++; (index < affectorsFiltered.length) && (affectorsFiltered[index].sub === affectorsFiltered[start].sub); index++) { end = index; } 


        let line = [];

        // output a whole category 

        // only add category label field if its required
        if (!hideCategoryLabel) {
            const rowTitle = { "label": affectorsFiltered[start].category, "rowSpan": 1+end-start};
            line.push(TableHeader({...rowTitle, "width": labelWidth, "className": "TableHeaderFixed"}));               // label for the group which  spans the other rows in a group
        }
        // for all roes in this category
        for (let row = start; row <= end; row++) {

            line.push(TableHeader({...affectorsFiltered[row], "width": labelWidth}));     // lable for the row
            
            const affectorId = affectorsFiltered[row].id; // id of the row
            //const category = affectorsFiltered[row].category;

            props.score.forEach(field => {

                const checked = props.record.hasOwnProperty(affectorId) ? (field.id === props.record[affectorId]) : (field.id === props.control.default);
   
                line.push(TableDataRadio({...field, "group": affectorId, "onChange": props.mutate, "readOnly": props.readOnly, "checked": checked}))
            });

            body.push(TableRow(line));
            line = [];
        }

    }

    output.push(ViewTableHeader(header, props.control.sub));
    output.push(ViewTableBody(body, props.control.sub));

    return ( 
        <>
            {ViewTable(output)}
            <ModalDialog onClick={onSelectAll} setModal={setModal} data={modal}/>
        </>
    )
}



const ViewSelect = (props) => {

    console.log('ViewSelect', props);

    const colTitle = { "label": props.control.label };

    let output = [];
    let line = []; 


    // Column Header rows
    line = []; 
    line.push(TableHeader(colTitle))


    var affectors; 
    switch (typeof(props.control.affector)) {
        case "object":  affectors = props.control.affector.map(item => (props.affector.find(affector => affector.id === item)));
                        break;
        case "string":  affectors = props[props.control.affector].map(item => item)                                                                     
                        break;
        default:        affectors = [];
    }
//"group":category, 
    line.push(TableDataSelect({"displayField": "label", "id": props.control.id, "name": "id", "onChange": props.mutate, "readOnly": props.readOnly, "options": affectors}));
    output.push(TableRow(line));
   
    return ViewTable(ViewTableBody(output, props.control.sub));
}







const ViewGroup = (props) => {



        // table and sub type specified for list
    const thisControl = props.control;
    const adminView = thisControl.adminView;
    let group = [];


    if (!Array.isArray(adminView)) {
        
        const table = thisControl.adminView.table;
        group = props[table].map(item => item)

        if        (adminView.hasOwnProperty("sub"))    { 

            const subset = adminView["sub"];    
            group = group.filter(filter => filter.sub === subset);

            console.log('ViewGroup SUB...................', subset, group);

        } else if (adminView.hasOwnProperty("aView")) {

             const subset = adminView["aView"];
             group = group.filter(filter => filter.aView === subset);


            console.log('ViewGroup  aView...................', subset, group);

        }
       // group = props.adminV
    }
    else {
        group = thisControl.adminView;
    }


    //group.sort((a, b) => (a.order.localeCompare(b.order)));

    console.log('VIEW GROUP',props, props.control, group);


    let output = [];

    group.forEach(control => {
        const outProps = {...props, control};
        output.push(ViewControl(outProps));
    });

    const key = `viewgroup_${thisControl.id}`;

   //console.log('key', key, thisControl.label);

    return (
        <div key={key}>
            <Accordion.Item eventKey={thisControl.order}>
                <Accordion.Header>
                    {thisControl.label}
                </Accordion.Header>
                <Accordion.Body>
                    {output}
                </Accordion.Body>
            </Accordion.Item>
        </div>
    )
}



const ViewControl = (props) => {
    const copy = props;
    //console.log('VIEW CONTROL',props);

    let output = [];


    switch (props.control.control) {

        case "group":    output = ViewGroup     (props); break;
        case "text":     output = ViewText      (props); break;
        case "textarea": output = ViewTextArea  (props); break;
        case "tickGrid": output = ViewTickGrid  (props); break;
        case "select":   output = ViewSelect    (props); break;
    }

   //console.log('ViewControl output:', output);

    return (output);

}



const ViewAdmin = (props) => {



    generalKey = 0;
    let output = [];
   
    const newProps = {...props.data  };
 
    //console.log('VIEW ADMIN',props,newProps);

    
    newProps.adminView.forEach(control => {
        if (control.sub === "aV") {
            // only call for top level adminViews i.e. "sub" = "aV".... ignore children
            newProps.control = control; 
            output.push(ViewControl({...newProps, control}))
        }
    });

    const key = `viewadmin`;

    return (<div key={key}>
                <Accordion defaultActiveKey={0} onSelect={newProps.changeTab} flush>
                    {output}
                </Accordion>
            </div>)

}


const ModalDialog = (props) => {
  
    const handleClose = () => (props.setModal(null));

    const show = (props.data != null);

    console.log('ModalDialog', props);

    if (show) {
        return (
        <>
            <Modal show={show}  onHide={handleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.data.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.data.body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" autoFocus value={props.data.noEvent} onClick={handleClose}>
                        {props.data.no}
                    </Button>
                    <Button variant="primary" value={props.data.yesEvent} name={props.data.yesName} onClick={props.onClick}>
                        {props.data.yes}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
        );
    }
    else {
        return null;
    }
  }
  




//const cardinfo = "d-flex justify-content-center"; //justify-center
const CRUDL = {"create": "C", "read": "R", "update": "U", "delete": "D", "list": "L"}; 


const ControlBar = (props) => {
    const [select, setSelect] = useState("");       // selected differential id (and trigger for render)
  //  const [showModal, setShowModal] = useState(null);

    const [modal, setModal] = useState(null);
  
    console.log('ControlBar', props);
    
    const state = props.state;

    //                      CRUDL button enabled based on state
    const newEnabled      = ["R","L"];
    const cancelEnabled   = ["C","U"];
    const deleteEnabled   = ["R"];
    const saveEnabled     = ["C","U"];
    const editEnabled     = ["R"];

   //console.log('STATExxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', state);

   const onClick = (event) => {  // button click
        console.log('ControlBar onClick', event.target.value);
        switch (event.target.value) {
            case "new":     setSelect(""); props.setRecord({}); props.setState(CRUDL.create);  
                            //props.setChanged(false);
                            //props.setFilter({});                                               
                                                                                                break;   

            case "edit":    props.setState(CRUDL.update);                                     
                            //props.setChanged(false);
                            //props.setFilter({});                                               
                                                                                                break;   
            case "cancelRequest":
                            setModal({"header": "Abandon Changes",
                                    "body": "Please Confirm if you wish to proceed", 
                                    "yes": "Yes Cancel",
                                    "no": "No",
                                    "yesEvent": "cancel"});                                      break;
                                                                      
            case "deleteRequest":
                            setModal({"header": "DELETE This Record",
                                    "body": "Please Confirm if you wish to proceed", 
                                    "yes": "Yes DELETE",
                                    "no": "No",
                                    "yesEvent": "delete"});                                     break;
                                                                                                
            case "saveRequest":
                            setModal({"header": "SAVE Changes",
                                    "body": "Please Confirm if you wish to proceed", 
                                    "yes": "Yes SAVE",
                                    "no": "No",
                                    "yesEvent": "save"});                                       break;
                                                             
            case "cancel":  
                            setModal(null);
                            setSelect("");                         
                            props.setRecord({}); 
                            props.setState(CRUDL.list);     
                            props.setChanged(false);
                            props.setFilter({});                                               
                                                                                                break;   


            case "delete":  
                            setModal(null);            
                            setSelect("");                             
                            props.onDelete(props.data.record);
                            props.setRecord({}); 
                            props.setState(CRUDL.list);                                         
                            //props.setChanged(false);
                            //props.setFilter({});                                               
                                                                                                break;   


            case "save":    
                            setModal(null);
                            setSelect(""); 
                            props.onSave(props.data.record);
                            props.setRecord({}); 
                            props.setState(CRUDL.list);                                         
                            props.setChanged(false);
                            props.setFilter({});                                                break;          
          
        }

   }

   const onChange = (event) => {                                    // loads the current differential based on user select

        console.log('ControlBar onChange', event.target.value);
        setSelect(event.target.value);                              // id of selected differential 

        const record = props.data.differential.find(record => record.id === event.target.value);

        if (typeof(record) === "object") {
            console.log('ControlBar onChange record', record);
            props.setRecord(record);
            props.setState(CRUDL.read);
         };
    }


   const selectorReadOnly = !props.data.readOnly;
   const title = selectorReadOnly ? "" : "Select Differential";
   const selector = {"displayField": SELECT_ID, "id": "diff_selector", "name": "id", "onChange": onChange, "readOnly": selectorReadOnly, "options": props.data.differential, "title": title, "value": select};
   //const selector = {"id": "diff_selector", "name": "id", "onChange": props.data.mutate, "readOnly": props.data.readOnly, "options": props.data.differential};
//  active={state=="C"}   


    const newDisabled       = !newEnabled.includes(state);
    const editDisabled      = !editEnabled.includes(state);
    const saveDisabled      = !saveEnabled.includes(state) || !props.changed;
    const deleteDisabled    = !deleteEnabled.includes(state);
    const cancelDisabled    = !cancelEnabled.includes(state);

    const newSelected       = newDisabled && (state !== CRUDL.create) ? "dark" : "primary";
    const editSelected      = editDisabled && (state !== CRUDL.update)? "dark" : "primary";
    const saveSelected      = saveDisabled  ? "dark" : "primary";
    const deleteSelected    = deleteDisabled ? "dark" : "primary";
    const cancelSelected    = cancelDisabled ? "dark" : "primary";


    const cancelEvent       =  props.changed ? "cancelRequest" : "cancel";

    return (
        <>
            <DataSelect data={selector} />{' '}

            <Button variant={newSelected}     value={"new"}             onClick={onClick} disabled={newDisabled}>New</Button>{' '}
            <Button variant={editSelected}    value={"edit"}            onClick={onClick} disabled={editDisabled}>Edit</Button>{' '}
            <Button variant={saveSelected}    value={"saveRequest"}     onClick={onClick} disabled={saveDisabled}>Save</Button>{' '}
            <Button variant={deleteSelected}  value={"deleteRequest"}   onClick={onClick} disabled={deleteDisabled}>Delete</Button>{' '}
            <Button variant={cancelSelected}  value={cancelEvent}       onClick={onClick} disabled={cancelDisabled}>Cancel</Button>{' '}

            <ModalDialog  onClick={onClick} setModal={setModal} data={modal}/>
        </>
    )
}

/*


            <Button variant="primary" value={"new"}    onClick={onClick} disabled={!newEnabled.includes(state)}>New</Button>{' '}
            <Button variant="primary" value={"edit"}   onClick={onClick} disabled={!editEnabled.includes(state)}>Edit</Button>{' '}
            <Button variant="primary" value={"save"}   onClick={onClick} disabled={!saveEnabled.includes(state) || !props.changed}>Save</Button>{' '}
            <Button variant="primary" value={"delete"} onClick={onClick} disabled={!deleteEnabled.includes(state)}>Delete</Button>{' '}
            <Button variant="primary" value=
*/

const ManageData = (props) => {

    console.log('ManageData', props);

    const [state, setState] = useState(CRUDL.list);         // CRUDL
    const [record, setRecord] = useState({});               // current differential
    const [filter, setFilter] = useState({});               // data presentation filter(s)
    const [changed, setChanged] = useState(false);          // one or more cuurent differemtial fields changed

    const changeTab = () => {                               // accordian changed
        const newRecord = {};
        setFilter(newRecord);

        console.log('FILTER CLEAR', newRecord);
    }
/*
    const onSave = (data) => {                               // save record
        console.log('props', data);
        UpdateRecord(data);
    }
*/


    

    const mutate = (event) => {                             // field changes

        let newRecord = {};

        if (event.hasOwnProperty("bulk")) 
        {
            newRecord = {...record};

            //const entries = Object.entries(event.bulk);
            //
                
            Object.entries(event.bulk).forEach(([key, value]) => {

                if (value === DEFAULT_PROBABILITY_ID)            // default not stored - so delete key value pair
                {
                    delete newRecord[key];
    
                } else {
                    newRecord[key] = value;
                }
    
            });

/*
            if (details.value === DEFAULT_PROBABILITY_ID)            // default not stored - so delete key value pair
            {
                delete newRecord[details.name];

            } else {
                newRecord[details.name] = details.value;
            }*/

            setRecord(newRecord);

            setChanged(true);

            console.log('RECORD--------------------------', newRecord); 

        } else {
            const details = event.target;       
            console.log('MUTATE', event, event.target, details);




            if (details.name.startsWith(FILTERS_IDS)) {              // special case for filters
                newRecord = {...filter};        
                newRecord[details.name] = details.value;
                setFilter(newRecord);
            //console.log('MUTATE filter++++++++++++++++++', filter);
            console.log('FILTER--------------------------', newRecord);
            }
            else {                                              // current differential field change
                newRecord = {...record};
                if (details.value === DEFAULT_PROBABILITY_ID)            // default not stored - so delete key value pair
                {
                    delete newRecord[details.name];

                } else {
                    newRecord[details.name] = details.value;
                }

                setRecord(newRecord);

                setChanged(true);

                console.log('RECORD--------------------------', newRecord);
            }
        }
    }

    const changeAllowed = ["R","L"];
    const readOnly = changeAllowed.includes(state);

    const data = {...props.data, record, filter, changeTab, mutate, readOnly};



    //const data = {record, data,filter, mutate};
    
    return (
        <>
            <ControlBar data={data} state={state} changed={changed} onDelete={props.onDelete} onSave={props.onSave} setFilter={setFilter} setState={setState} setRecord={setRecord} setChanged={setChanged} />
            <ViewAdmin data={data} />
        </>
    );
}

/*
.then(res => { 
    setAdminData(null);
    InitialiseData(setData);
});

*/

const UpdateRecord = async (record, setState) => {
    ApiPut('item', JSON.stringify(record))
    .then(res => { 
        console.log('UpdateRecord', res);
        setState(null);
        InitialiseData(setState);
    })
    .then()
    .catch(err => {
        console.log('UpdateRecord Error', err);
        //console.log('EpdFullApiLoad', err);
        //setState({ loading: false, user: [], data: [], lastUpdate: null, err: err });
    });
}


const DeleteRecord = async (record, setState) => {

    const deleteRecord = JSON.stringify(`/${DIFFERENTIAL_TABLE}/${record.id}`); 

    ApiDelete('item', deleteRecord)
    .then(res => { 
        console.log('DeleteRecord', res);
        setState(null);
        InitialiseData(setState);
    })
    .then()
    .catch(err => {
        console.log('DeleteRecord Error', err);
        //console.log('EpdFullApiLoad', err);
        //setState({ loading: false, user: [], data: [], lastUpdate: null, err: err });
    });
}

const InitialiseData = async (setState) => {

      console.log('InitialiseData ***************************************************');
/*
    //console.log('InitialiseData', setState);
    let InitData = [];

    console.log('InitialiseData', InitData);

    dataImport.forEach(item => {
        //console.log('ITEM', item);


        item.id = camelize(item.sub)+"#"+camelize(item.label);

        //InitData[item.type] = [];

        //console.log('Data to push', item.type, item);

        if (!InitData.hasOwnProperty(item.type)) { InitData[item.type] = [] }; // first time creation
        InitData[item.type].push(item);
    });

    console.log('InitData', InitData);

    InitData.adminView.sort((a, b) => (a.order - b.order));

    //InitData.affector = InitData.affector.map(obj => ({...obj, "id": camelize(obj.sub)+"#"+camelize(obj.label)}));
    InitData.affector.sort((a, b) => (a.id.localeCompare(b.id)));

    InitData.differential.sort((a, b) => (a.id.localeCompare(b.id)));
    InitData.score.sort((a, b) => (a.score - b.score));

    //InitData.dogBreed = InitData.dogBreed.map(obj => ({...obj, "id": obj.label.replaceAll(" ","_")}));
    //InitData.dogBreed = InitData.dogBreed.map(obj => ({...obj, "id": camelize(obj.label)}));
    
    //InitData.dogBreed.sort((a, b) => (a.id.localeCompare(b.id)));

    //console.log('Camel',  InitData.dogBreed);

    console.log('LIST', InitData);

    setState(InitData)

*/
    //const InitData = data; // data is currently global
    //let InitData = []; // data is currently global


    console.log('Ready to get');

     ApiGet('items')
    .then(res => { 
        console.log('InitialiseData', res);

        console.log('RES', res);

        //const Items = res.Items;

        const InitData = []; // data is currently global

        InitData[ADMIN_VIEW_TABLE]      = [];
        InitData[AFFECTOR_TABLE]        = [];
        InitData[DIFFERENTIAL_TABLE]    = [];
        InitData[SCORE_TABLE_TABLE]     = [];


        res.Items.forEach(item => {
            //console.log('ITEM', item.type, item);
            //if (!InitData.hasOwnProperty(item.type)) { InitData[item.type] = [] };
            //InitData[item.type] = [];
            InitData[item.type].push(item);
        });
   
        /*
        InitData.adminView.sort((a, b) => (a.order.localeCompare(b.order)));
        InitData.affector.sort((a, b) => (a.id.localeCompare(b.id)));
        InitData.differential.sort((a, b) => (a.id.localeCompare(b.id)));
        InitData.score.sort((a, b) => (a.score - b.score));
    
        //InitData.dogBreed = InitData.dogBreed.map(obj => ({...obj, "id": obj.label.replaceAll(" ","_")}));
        InitData.dogBreed = InitData.dogBreed.map(obj => ({...obj, "id": camelize(obj.label)}));
        
        InitData.dogBreed.sort((a, b) => (a.id.localeCompare(b.id)));
        */

        InitData.adminView.sort((a, b) => (a.order - b.order));

        //InitData.affector = InitData.affector.map(obj => ({...obj, "id": camelize(obj.sub)+"#"+camelize(obj.label)}));
        //InitData.affector.sort((a, b) => (a.id.localeCompare(b.id)));
        InitData.affector.sort((a, b) => {

            const aOrder = a.hasOwnProperty("order") ? a.sub+String(a["order"]).padStart(3, 0) : a.id;
            const bOrder = b.hasOwnProperty("order") ? b.sub+String(b["order"]).padStart(3, 0) : b.id;

            if (a.hasOwnProperty("order")) {console.log('ORDER', aOrder, bOrder)}

            return (aOrder.localeCompare(bOrder));
        });
            
        InitData.score.sort((a, b) => (a.score - b.score));
        
        InitData.differential.sort((a, b) => (a.id.localeCompare(b.id)));

        //console.log('Camel',  InitData.dogBreed);
    
        console.log('LIST', InitData);

        setState(InitData)
    })
    .catch(err => {
        console.log('InitialiseData Error', err);
        //console.log('EpdFullApiLoad', err);
        //setState({ loading: false, user: [], data: [], lastUpdate: null, err: err });
    });


}



const Admin = () => {
    
    const [adminData, setAdminData] = useState(null);
    //const [refresh, setRefresh] = useState(0);
/*
    const setData = (state) => {                // called when InitialiseData refreshes data
        const temp = state;
        console.log('set Data', temp);
        setAdminData(temp);
        //setRefresh(refresh + 1);
    }
*/

    const onSave = (data) => {                               // save record
        console.log('props', data);
        UpdateRecord(data, setAdminData);
    }

    const onDelete = (data) => {                               // save record
        console.log('props', data);
        DeleteRecord(data, setAdminData);
    }

    useEffect(() => {
        InitialiseData(setAdminData);
    }, []);
/*
    useEffect(() => {
        const mystate = (adminData == []);
        console.log('Array Length Early', (adminData == []), mystate, adminData);
        console.log('data changes');
    });
*/
/*
    useEffect(() => {
        const temp = null;
        console.log('Temo Length', (temp == null), temp);
        const mystate = (adminData == []);
        console.log('Array Length', (adminData == []), mystate, adminData);
    },[adminData]);
*/

    const textStyle = {
        textAlign: "center"
    };

    // console.log("adminData.length", adminData.length);
    if (true) {

        return (

           

            <Container fluid="lg">
                 { console.log("STATE", adminData, (adminData == null))}
                <Row>
                    <Col>
                        <h1 style={textStyle}>Vet Admin</h1>
                        { (adminData === null) && <div style={textStyle}><Spinner  animation="border" /></div> }
                        { (adminData !== null) && <ManageData data={adminData} onDelete={onDelete} onSave={onSave}/> }
                    </Col>
                </Row>
            </Container>
        );

    }
    else {

        return (
            <Container fluid="lg">
                <Row>
                    <Col>
                            <h1 style={textStyle}>Vet Admin</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ManageData data={adminData}/>

                    </Col>
                </Row>
            </Container>

        );
    }
}

export default Admin;

