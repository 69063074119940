function setConfig() {

    const dev = {

        Environment: 'development',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_6O0ur6za5',
            userPoolWebClientId: 'in89mr9j9l7meh67ndv96qtn5',

            API: {
                endpoints: [
                    {
                        name: "epD-API-Gateway",
                        endpoint: " https://qxqy3m7cq8.execute-api.eu-west-2.amazonaws.com",
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'epD-API-Gateway',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    }

    const stage = {
 
        Environment: 'staging',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_6O0ur6za5',
            userPoolWebClientId: '3ap9sosiu57vlgplgqa79okppe', /* CloudFormation */
            API: {
                endpoints: [
                    {
                        name: "epD-API-Gateway",
                        endpoint: " https://dev.vet.easypeasydigital.com"
                    }
                ]
            }
        },
        ApiGateway: {  // stage uses the dev instance of api gateway

            apiName: 'epD-API-Gateway',
            apiPath: '/api/', // the (dev) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    }



    const prod = {

        Environment: '',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_EnDtLPdxb', 
            userPoolWebClientId: '1s5a3slpgp3vrpj1ehjmj3eodh', /* CloudFormation */

            API: {
                endpoints: [
                    {
                        name: "epD-API-Gateway",
                        endpoint: "https://admin.easypeasydigital.com",
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'epD-API-Gateway',
            apiPath: '/api/', // the (prod) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Methods": "*",
                    "Access-Control-Allow-Origin": "*"
                }
            }
        }
    };



    switch (process.env.REACT_APP_STAGE.trim()) {
        case 'prod':
            return (prod);

        case 'stage':
            return (stage);

        case 'dev':
        default:
            return (dev);
    }
}


//const config = (process.env.REACT_APP_STAGE === 'prod') ? prod : dev;


const config = setConfig();

const awsconfig = config.Auth;
const environment = config.Environment + ' 0v01';
const apiGateway = config.ApiGateway;





//consconfit awsconfig = dev.Auth;
//const Environment = process.env.REACg.T_APP_STAGE;

export { awsconfig, environment, apiGateway };
