import { Auth, API } from 'aws-amplify';
import { apiGateway } from './config';

const ApiDelete= async (path, method='') => {
    const parameters = apiGateway;

    parameters.apiInit.headers["Authorization"] = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    //parameters.apiInit.body = (method === '') ? '' : parameters.apiInit.body = JSON.parse(method);

    console.log("apiDelete",parameters);
    return await API.del(parameters.apiName, parameters.apiPath+path+JSON.parse(method), parameters.apiInit);
}

const  ApiPost = async (path, body='') => {

    const parameters = apiGateway;


    parameters.apiInit.headers["Authorization"] = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    parameters.apiInit.body = (body === '') ? '' : parameters.apiInit.body = JSON.parse(body);
  
    //return await API.get(apiName, path, myInit);
    return await API.post(parameters.apiName, parameters.apiPath+path, parameters.apiInit);
}

const  ApiPut = async (path, body='') => {

    const parameters = apiGateway;
    
    parameters.apiInit.headers["Authorization"] = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    parameters.apiInit.body = (body === '') ? '' : parameters.apiInit.body = JSON.parse(body);
  
    //return await API.get(apiName, path, myInit);
    return await API.put(parameters.apiName, parameters.apiPath+path, parameters.apiInit);
}

const  ApiGet = async (path) => {

    const parameters = apiGateway;

    parameters.apiInit.headers["Authorization"] = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  
    //return await API.get(apiName, path, myInit);
    return await API.get(parameters.apiName, parameters.apiPath+path, parameters.apiInit);
}

const ApiHeaders = async () => {
    
    //const headers = apiGateway.apiInit.headers;
    apiGateway.apiInit.headers["Authorization"] = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    //const headers = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    //return headers;
}


const  ApiGetNoWait = async (path) => {

    await ApiHeaders();

    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}

const  ApiGetArray = async (getArray) => {

    await ApiHeaders();

    const output = getArray.map(item => API.get(apiGateway.apiName, apiGateway.apiPath+item, apiGateway.apiInit));

    console.log('ApiGetArray', output);

    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])


    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  Promise.all(output); //API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}


const  ApiCall = async (item) => {

    console.log('PATH', item);

    const path = item.resource;
    let response =null;

    switch (item.method) {
        case    'POST': const init = apiGateway.apiInit;
                        init.body = item.body; //JSON.stringify(item.body);
                        response = API.post(apiGateway.apiName, apiGateway.apiPath+path, init); console.log('POST', init); break;
        case    'GET' :
        default       : response = API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit); console.log('GET');
    }


    return response;
}


const  ApiCallArray = async (callArray) => {

    await ApiHeaders();

    console.log('ApiCallArray', Array.isArray(callArray));

    const output = Array.isArray(callArray) ? callArray.map(item => ApiCall(item)) : ApiCall(callArray); // single entry


    console.log('ApiCallArray', output);

    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])


    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  Promise.all(output); //API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}


export {ApiDelete, ApiPut, ApiPost, ApiGet, ApiGetNoWait, ApiGetArray, ApiCallArray, ApiHeaders};
  