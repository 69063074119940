import React,  { useState, useEffect }  from 'react';
import { Button, Spinner } from 'react-bootstrap';
import {refreshTimeoutMins, minsOld} from './Library'


// https://fontawesome.com/docs/web/use-with/reactimport { Container, Row, Col, Spinner, Button } from 'react-bootstrap';/
// https://fontawesome.com/v6/icons
// https://github.com/FortAwesome/Font-Awesome/blob/master/UPGRADING.md#50x-to-510
// https://fontawesome.com/v4/icons/
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faRefresh } from '@fortawesome/free-solid-svg-icons'


const Refresh = (props) =>  {
    const [seconds, setSeconds] = useState(0);
    const [toggle, setToggle] = useState(false);
    //const [isActive, setIsActive] = useState(false);

   // function reset() {
     //   setSeconds(0);
        //setIsActive(false);
    //  }
    /*
      useEffect(() => {
        let interval = null;
        if (isActive) {
          interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
          }, 1000);
        } else if (!isActive && seconds !== 0) {
          clearInterval(interval);
        }
        return () => clearInterval(interval);
      }, [isActive, seconds]);
    */

      useEffect(() => {
        let interval = null;

          interval = setInterval(() => {
            setToggle(toggle => !toggle);
          }, 10000); // trigger every 10 secs

        return () => clearInterval(interval);
      }, [toggle]);
    
    //const 

    const DEFAULT = "secondary";

    const age = minsOld(props.state.lastUpdate);
    let variant = DEFAULT;
    if      (age >= refreshTimeoutMins.EXPIRED) {
            variant = "danger";
    } else {
        if (age >= refreshTimeoutMins.REFRESHABLE) {
            variant = "warning";
        }
    }

    //console.log('Refresh', props.state);

    if        (props.state.loading) {

        return(<Button className="mr-1 ml-1" variant={variant}><Spinner as="span" size="sm" role="status" aria-hidden="true" animation="border" /></Button>);
   
    } else if (props.state.lastUpdate === null) {
      
        return  null; // display nothing if it has never been updated (this occurs on first invocation)
      
    } else if (variant === DEFAULT) {

          //return(<Button className="mr-1 ml-1" variant={variant} disabled><FontAwesomeIcon icon={faRefresh}/></Button>);
        
    } else {
  
          //return(<Button className="mr-1 ml-1" variant={variant}><FontAwesomeIcon icon={faRefresh}/></Button>);        
    
    }
}


class xxxxRefresh extends React.Component {
    constructor(props) {
        super(props);

        this.action = this.action.bind(this);
    }

    action = () => {
        //this.props.toggle(this.props.index);
    }
        


    render() {

        const age = minsOld(this.props.state.lastUpdate);
        let variant = "secondary";
        if      (age >= refreshTimeoutMins.EXPIRED) {
                variant = "danger";
        } else {
            if (age >= refreshTimeoutMins.REFRESHABLE) {
                variant = "warning";
            }
        }

        if (this.props.state.loading) {
            return(<Button variant={variant}><Spinner as="span" size="sm" role="status" aria-hidden="true" animation="border" /></Button>);
        } else {
            //return(<Button variant={variant}><FontAwesomeIcon icon={faRefresh}/></Button>);
        }
    }
}

export default Refresh;

//        return(<div className="pt-1 pb-1"><Button  variant="secondary" onClick={this.action} size="sm"dia><Spinner size="sm" animation="border" /></Button></div>);
